import React from "react";
import { TermsContainer, TermsHeader, TermsContent } from "./Term.elements";

const Term = () => {
  return (
    <TermsContainer>
      <TermsHeader>الشروط والأحكام</TermsHeader>
      <TermsContent>
        <p>
          نرحب بكم في موقع فلونت فوكس. باستخدام هذا الموقع، فإنك توافق على
          الالتزام بالشروط والأحكام التالية:
        </p>
        <ol>
          <li>
            المقدمة: تحدد هذه الشروط والأحكام القواعد والبنود الخاصة باستخدام
            هذا الموقع.
          </li>
          <li>
            استخدام الموقع: يجب استخدام الموقع لأغراض قانونية فقط وبطريقة لا
            تنتهك حقوق الآخرين.
          </li>
          <li>
            المحتوى: جميع المحتويات على هذا الموقع هي ملك لفلونت فوكس ولا يجوز
            نسخها أو إعادة إنتاجها بدون إذن.
          </li>
          <li>
            التعديلات: يحق لفلونت فوكس تعديل هذه الشروط والأحكام في أي وقت.
          </li>
          <li>
            القانون الواجب التطبيق: تخضع هذه الشروط والأحكام وتفسر وفقاً لقوانين
            المملكة العربية السعودية.
          </li>
          <li>
            استخدام المعلومات: باستخدام هذا الموقع وتسجيل حسابك، فإنك تمنح مالك
            فلونت فوكس الحق في استخدام معلوماتك بما في ذلك البريد الإلكتروني
            ورقم الهاتف للإعلانات وجميع الأعمال والمشاريع الخاصة به.
          </li>
          <li>
            الخصوصية: نحن ملتزمون بحماية خصوصيتك. يرجى مراجعة إشعار الخصوصية
            الخاص بنا لمعرفة كيفية جمع واستخدام وحماية معلوماتك الشخصية.
          </li>
          <li>
            أمن الحساب: أنت مسؤول عن الحفاظ على سرية معلومات حسابك وكلمة المرور.
            نوصي بعدم مشاركة كلمة المرور الخاصة بك مع أي شخص.
          </li>
          <li>
            حدود المسؤولية: لا يتحمل فلونت فوكس أي مسؤولية عن أي أضرار مباشرة أو
            غير مباشرة تنتج عن استخدام أو عدم القدرة على استخدام الموقع أو أي
            محتوى أو خدمات ذات صلة.
          </li>
          <li>
            إنهاء الخدمة: يحتفظ فلونت فوكس بالحق في إنهاء أو تعليق وصولك إلى
            الموقع في أي وقت، دون إشعار مسبق، إذا كنت قد انتهكت هذه الشروط
            والأحكام أو إذا اعتبرنا أنك تشكل تهديدًا لموقعنا أو مستخدمينا.
          </li>
          <li>
            روابط الطرف الثالث: قد يحتوي الموقع على روابط لمواقع أخرى. نحن لسنا
            مسؤولين عن محتوى أو ممارسات الخصوصية في هذه المواقع.
          </li>
          <li>
            النزاعات: أي نزاع ينشأ عن استخدامك لهذا الموقع سيتم حله وفقًا
            لقوانين المملكة العربية السعودية.
          </li>
          <li>
            الدفع: تتم جميع عمليات الدفع من خلال متجر Shopify الخاص بنا على
            العنوان التالي:{" "}
            <a
              href="https://fluentfox.shop"
              target="_blank"
              rel="noopener noreferrer"
            >
              fluentfox.shop
            </a>
            .
          </li>
          <li>
            سياسة الاسترجاع: نظرًا لطبيعة المنتجات الرقمية، فإن جميع المبالغ
            المدفوعة غير قابلة للاسترداد.
          </li>
        </ol>
      </TermsContent>
    </TermsContainer>
  );
};

export default Term;
